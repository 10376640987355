<template>
  <div class="orderManagementDetailsPageBox page-info-content">
    <Details-page :detail-obj="detailObj" :details-item-arr="detailsItemArr" :table-title="'商品详情'" :item-data="itemData" :list-data="listData" />
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage.vue'
import { orderInfoDetail } from '@/api/transactionData'
export default {
  components: { DetailsPage },
  data() {
    return {
      detailObj: {},
      itemData: [
        { label: '品名', prop: 'detailGoodsName' },
        { label: '材质', prop: 'detailGoodsMaterials' },
        { label: '规格', prop: 'detailGoodsSpecs' },
        { label: '产地', prop: 'detailGoodsProduct' },
        { label: '件重(吨)', prop: 'detailGoodsWeights' },
        { label: '长度(米)', prop: 'detailLength' },
        { label: '数量', prop: 'detailQuantity' },
        { label: '数量(吨)', prop: 'detailWeight' },
        { label: '单价(含税/元)', prop: 'detailPrice' },
        { label: '合计(含税/元)', prop: 'detailTotalPrice' }
      ],
      listData: [],
      detailsItemArr: [
        { label: '合同编号', value: 'contractNo' },
        { label: '关联合同编号', value: 'relaContractNo' },
        { label: '采购方', value: 'buyerCmpName', width: 200 },
        { label: '采购方统一社会信用代码', value: 'buyerCmpUnicode', width: 200 },
        { label: '销售方', value: 'sellerCmpName', width: 200 },
        { label: '销售方统一社会信用代码', value: 'sellerCmpUnicode', width: 200 },
        { label: '合同金额(元)', value: 'orderAmount', rightWords: '元' },
        { label: '订单完成日期', value: 'orderCompleteDate' },
        { label: '订单签订日期', value: 'orderSignDate' },
        { label: '订单状态', value: 'orderStatus' },
        { label: '订单总数量', value: 'orderTotalWeight' },
        { label: '附件', value: 'examine', link: 'contractFile' }
      ]
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getDetails()
    }
  },
  methods: {
    getDetails() {
      orderInfoDetail(this.$route.query.id, res => {
        this.detailObj = res.data
        this.detailObj.examine = '下载查看'
        if (res.data.impOrderInfoDetailRespList) this.listData = [...res.data.impOrderInfoDetailRespList]
      })
    }
  }
}
</script>
